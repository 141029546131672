<template>
  <section id="hero">
    <v-img
      :src="require('@/assets/hintergrunf-ws.jpg')"
      height="100vh"
    >
      <v-row
        :justify="$vuetify.breakpoint.mdAndUp ? 'end' : undefined"
        align="end"
        class="fill-height text-right pa-md-5 pa-3 mx-0"
      >
        <div>
        </div>
      </v-row>
    </v-img>
  </section>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'Hero',

    computed: {
      ...mapState('app', ['schema']),
    },
  }
</script>
